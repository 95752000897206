import React, { useContext } from 'react';
import '../../index.css';
import './case.css';

import SiteContent from '../../config/SiteContent';
import { LanguageContext } from '../../context/LanguageContextProvider';

import Nav from '../../components/base/nav/Nav';
import CaseHero from '../../components/cases/casehero/CaseHero';
import Quote from '../../components/cases/quote/Quote';
import Info from '../../components/cases/info/Info';
import Footer from '../../components/base/footer/Footer';

import image_1 from '../../assets/images/cases/avitms/image_1.png';
import paula from '../../assets/images/cases/avitms/paula.jpeg';

export default function Avitms() {
    const { lang } = useContext(LanguageContext);
    return (
        <div className="case page avitms">
            <Nav />
            {SiteContent[lang].case.websites.casehero ? <CaseHero
                client={SiteContent[lang].case.avitms.casehero.client}
                title={SiteContent[lang].case.avitms.casehero.title}
                type={SiteContent[lang].case.avitms.casehero.type}
                image={image_1}
            /> : null}
            {SiteContent[lang].case.websites.info_1 ? <Info 
                title={SiteContent[lang].case.avitms.info_1.title}
                text={SiteContent[lang].case.avitms.info_1.text}
            /> : null}
            {SiteContent[lang].case.websites.info_2 ? <Info
                noPaddingTop
                title={SiteContent[lang].case.avitms.info_2.title}
                text={SiteContent[lang].case.avitms.info_2.text}
            /> : null}
            {SiteContent[lang].case.websites.quote ? <Quote 
                words={SiteContent[lang].case.avitms.quote.words}
                author={SiteContent[lang].case.avitms.quote.author}
                image={paula}
                profession={SiteContent[lang].case.avitms.quote.profession}
            /> : null}
            {SiteContent[lang].case.avitms.info_3 ? <Info 
                title={SiteContent[lang].case.avitms.info_3.title}
                text={SiteContent[lang].case.avitms.info_3.text}
            /> : null}
            <Footer 
                title={SiteContent[lang].footer.title}
                text={SiteContent[lang].footer.text}
                phone={SiteContent[lang].footer.phone}
            />
        </div>
    )
}
