import React from 'react';
import '../../../index.css';
import './quote.css';

const Quote = ({ words, author, profession, image, imageAlt }) => (
    <div className="quote">
        <div className="inner">
            <h3>"{words}"</h3>
            <div className="quoting">
                <div className="container">
                    <div className="arrow"></div>
                    {image ?
                        <>
                            <img src={image} alt={imageAlt}/> 
                            <div className="text">
                                <p>{author}</p>
                                <p className="small">{profession}</p>
                            </div>
                        </>
                    : 
                        <div className="text" style={{ marginLeft: 100}}>
                            <p>{author}</p>
                            <p className="small">{profession}</p>
                        </div>
                    }        
                </div>
            </div>
        </div>
    </div>
)

export default Quote;

