import React from "react";
const LanguageContext = React.createContext();

const useStateWithLocalStorage = (localStorageKey) => {
    const [value, setValue] = React.useState(
        localStorage.getItem(localStorageKey) || "dutch"
    );

    React.useEffect(() => {
        localStorage.setItem(localStorageKey, value);
    }, [value, localStorageKey]);

    return [value, setValue];
};

const LanguageContextProvider = (props) => {
    const [lang, setLang] = useStateWithLocalStorage("lang");

    const context = { lang, setLang };

    return (
        <LanguageContext.Provider value={context}>
            {props.children}
        </LanguageContext.Provider>
    );
};
export { LanguageContext, LanguageContextProvider };
