import React, { useContext } from 'react';
import './team.css';
import '../../index.css';
import Member from './member/Member';

import SiteContent from '../../config/SiteContent';
import { LanguageContext } from '../../context/LanguageContextProvider';

import Bram from '../../assets/images/team/bram.jpg';
import Frederik from '../../assets/images/team/frederik.jpg';
import JM from '../../assets/images/team/jean-micheal.jpg';
import Jochem from '../../assets/images/team/jochem.jpg';
import Jur from '../../assets/images/team/jur.jpg';
import Lisanne from '../../assets/images/team/lisanne.jpg';
import Tarquin from '../../assets/images/team/tarquin.png';
import Monster from '../../assets/images/team/monster.jpg';
import Pim from '../../assets/images/team/pim.jpg';
import Remy from '../../assets/images/team/remy.jpg';
import Stefan from '../../assets/images/team/stefan.jpg';
import Vincent from '../../assets/images/team/vincent.jpg';

export default function Team() {
    const { lang } = useContext(LanguageContext);
    return (
        <div className="team white-block">
            <div className="inner">
                <h2>{SiteContent[lang].about.ourTeam.title}</h2>
                {/* <Member
                    image={Jur}
                    name={SiteContent[lang].about.member[0].name}
                    job= {SiteContent[lang].about.member[0].job}
                /> */}
                <Member
                    image={Stefan}
                    name={SiteContent[lang].about.member[1].name}
                    job= {SiteContent[lang].about.member[1].job}
                />
                <Member
                    image={Lisanne}
                    name={SiteContent[lang].about.member[2].name}
                    job= {SiteContent[lang].about.member[2].job}
                />
                <Member
                    image={Jochem}
                    name={SiteContent[lang].about.member[3].name}
                    job= {SiteContent[lang].about.member[3].job}
                />
                <Member
                    image={JM}
                    name={SiteContent[lang].about.member[4].name}
                    job= {SiteContent[lang].about.member[4].job}
                />
                <Member
                    image={Remy}
                    name={SiteContent[lang].about.member[5].name}
                    job= {SiteContent[lang].about.member[5].job}
                />
                <Member
                    image={Frederik}
                    name={SiteContent[lang].about.member[6].name}
                    job= {SiteContent[lang].about.member[6].job}
                />
                <Member
                    image={Bram}
                    name={SiteContent[lang].about.member[7].name}
                    job= {SiteContent[lang].about.member[7].job}
                />
                <Member
                    image={Pim} 
                    name={SiteContent[lang].about.member[8].name}
                    job= {SiteContent[lang].about.member[8].job}
                />
                <Member
                    image={Vincent}
                    name={SiteContent[lang].about.member[9].name}
                    job= {SiteContent[lang].about.member[9].job}
                />
                <Member
                    image={Tarquin}
                    name={SiteContent[lang].about.member[10].name}
                    job= {SiteContent[lang].about.member[10].job}
                />
                <Member
                    image={Monster}
                    name={SiteContent[lang].about.member[11].name}
                    job= {SiteContent[lang].about.member[11].job}
                />
            </div>
        </div>
    )
}
