import React from 'react';
import '../../index.css';
import './errors.css';
import { Link } from 'react-router-dom';
import Nav from '../../components/base/nav/Nav';

class NotFoundPage extends React.Component{
    render(){
        return (
            <div className="page-404">
                <Nav />
                <div className="inner">
                    <div className="error-type" title="404">404</div>
                    <p>Glitch! Helaas deze pagina bestaat niet.</p>
                    <Link to="/" className="button">Ga naar home</Link>
                </div>
            </div>
        )
    }
}
export default NotFoundPage;
