import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import {LanguageContextProvider} from './context/LanguageContextProvider';

function App() {
  return (
    <LanguageContextProvider>
      <div className="App">
        <Router>
          <Routes/>
        </Router>
      </div>
    </LanguageContextProvider>
  );
}

export default App;
