import React, {useContext} from 'react';
import '../../index.css';
import './fundamentals.css';

import SiteContent from '../../config/SiteContent';
import { LanguageContext } from '../../context/LanguageContextProvider';

import Nav from '../../components/base/nav/Nav';
import Hero from '../../components/hero/Hero';
import Filter from '../../components/filter/Filter';
import Thumbnail from '../../components/cases/thumbnail/Thumbnail';
import Footer from '../../components/base/footer/Footer';

export default function Refactoring() {
    const { lang } = useContext(LanguageContext);
    return (
        <div className="refactoring page">
            <Nav/>
            <Hero
                small
                title={SiteContent[lang].cases.refactoring.hero.title}
                text={SiteContent[lang].cases.refactoring.hero.text}
            />
            <Filter refactoring/>
            <Thumbnail thumbnailData={SiteContent[lang].cases.refactoring.thumbnails}/>
            <Footer/>
        </div>
    )
}
