import React, { useContext } from 'react';
import '../../index.css';
import './case.css';

import SiteContent from '../../config/SiteContent';
import { LanguageContext } from '../../context/LanguageContextProvider';

import Nav from '../../components/base/nav/Nav';
import CaseHero from '../../components/cases/casehero/CaseHero';
import Quote from '../../components/cases/quote/Quote';
import Info from '../../components/cases/info/Info';
import Image from '../../components/cases/image/Image';
import Footer from '../../components/base/footer/Footer';

import image_1 from '../../assets/images/cases/finalyser/koppeling.png';
import image_2 from '../../assets/images/cases/finalyser/finalyser-image.png';

export default function Finalyser() {
    const { lang } = useContext(LanguageContext);
    return (
        <div className="case page finalyser">
            <Nav />
            {SiteContent[lang].case.websites.casehero ? <CaseHero
                client={SiteContent[lang].case.finalyser.casehero.client}
                title={SiteContent[lang].case.finalyser.casehero.title}
                type={SiteContent[lang].case.finalyser.casehero.type}
                image={image_2}
            /> : null}
            {SiteContent[lang].case.websites.info_1 ?  <Info 
                title={SiteContent[lang].case.finalyser.info_1.title}
                text={SiteContent[lang].case.finalyser.info_2.text}
            /> : null}
            <Image 
                image={image_1}
            />
            {SiteContent[lang].case.websites.info_2 ? <Info 
                title={SiteContent[lang].case.finalyser.info_2.title}
                text={SiteContent[lang].case.finalyser.info_2.text}
            /> : null}
            {SiteContent[lang].case.websites.quote ? <Quote 
                words={SiteContent[lang].case.finalyser.quote.words}
                author={SiteContent[lang].case.finalyser.quote.author}
                profession={SiteContent[lang].case.finalyser.quote.profession}
            /> : null}
            {SiteContent[lang].case.websites.info_3 ? <Info 
                title={SiteContent[lang].case.finalyser.info_3.title}
                text={SiteContent[lang].case.finalyser.info_3.text}
            /> : null}

            <Footer 
                title={SiteContent[lang].footer.title}
                text={SiteContent[lang].footer.text}
                phone={SiteContent[lang].footer.phone}
            />
        </div>
    )
}
