import React from 'react';
import './clients.css';
import '../../index.css';

import Heineken from '../../assets/images/clients/logo_heineken.png';
import ThreatLabs from '../../assets/images/clients/logo_threatlabs.svg';
import Vergeer from '../../assets/images/clients/logo_vergeer.png';
import Finalyser from '../../assets/images/clients/logo_finalyser.svg';
import Attitude from '../../assets/images/clients/logo_attitude.png';
import Jaato from '../../assets/images/clients/logo_jaato.png';
import Monaliro from '../../assets/images/clients/logo_monaliro.png';
import Voyp from '../../assets/images/clients/logo_voyp.png';
import Seamless from '../../assets/images/clients/logo_seamless.png';
import Castro from '../../assets/images/clients/logo_castro.png';
import Avitms from '../../assets/images/clients/logo_avitms.png';
import Thenewworker from '../../assets/images/clients/logo_thenewworker.png';
import Kugis from '../../assets/images/clients/logo_kugis.png';
import Fxdc from '../../assets/images/clients/logo_fxdc.png';
import Nextfin from '../../assets/images/clients/logo_nextfin.png';
import NextXS from '../../assets/images/clients/logo_nextxs.png';




const Clients = ({ title }) => (
    <div className="clients">
        <div className="inner">
            <h2>{title}</h2>
            <div className="row">
                <div className="col">
                    <img src={Heineken} alt=""/>
                </div>
                <div className="col">
                    <img src={ThreatLabs} alt=""/>
                </div>
                <div className="col">
                    <img src={Vergeer} alt=""/>
                </div>
                <div className="col">
                    <img src={NextXS} alt=""/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img src={Finalyser} alt=""/>
                </div>
                <div className="col">
                    <img src={Attitude} alt=""/>
                </div>
                <div className="col">
                    <img src={Seamless} alt=""/>
                </div>
                <div className="col">
                    <img src={Voyp} alt=""/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img src={Jaato} alt=""/>
                </div>
                <div className="col">
                    <img src={Monaliro} alt=""/>
                </div>
                <div className="col">
                    <img src={Castro} alt=""/>
                </div>
                <div className="col">
                    <img src={Avitms} alt=""/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img src={Thenewworker} alt=""/>
                </div>
                <div className="col">
                    <img src={Fxdc} alt=""/>
                </div>
                <div className="col">
                    <img src={Nextfin} alt=""/>
                </div>
                <div className="col">
                    <img src={Kugis} alt=""/>
                </div>
            </div>
        </div>
    </div>
)

export default Clients;
