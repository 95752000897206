import React, { useContext, useEffect, useState } from "react";
import "./nav.css";
import "../../../index.css";
import SiteContent from "../../../config/SiteContent";
import { LanguageContext } from "../../../context/LanguageContextProvider";
import menu from "../../../assets/svgs/menu.svg";
import close from "../../../assets/svgs/close.svg";
import JsA from "../jsa/JsA";
export default function Nav() {
    // Toggle mobile menu
    const [show, setShow] = useState(false);

    // Get current page
    const getCurrentPage = () => {
        if (window.location.href.includes("cases")) {
            return "cases";
        }
        if (window.location.href.includes("case")) {
            return "cases";
        }
        if (window.location.href.includes("platforms")) {
            return "cases";
        }
        if (window.location.href.includes("strategy")) {
            return "cases";
        }
        if (window.location.href.includes("refactoring")) {
            return "cases";
        }
        if (window.location.href.includes("aviation")) {
            return "cases";
        }
        if (
            window.location.href.includes("about") ||
            window.location.href.includes("over-ons")
        ) {
            return "about";
        }
        if (window.location.href.includes("contact")) {
            return "contact";
        }
        return "home";
    };

    const cur_page = getCurrentPage();

    // Background color nav
    const scrollFunction = () => {
        const nav = document.querySelector("nav");
        const navHeight = nav.offsetHeight;
        const whiteBlocks = document.querySelectorAll(".white-block");

        let anyTrue = false;
        whiteBlocks.forEach(function (whiteBlock) {
            let boundingBlock = whiteBlock.getBoundingClientRect();
            if (
                boundingBlock.top < navHeight &&
                boundingBlock.bottom > navHeight
            ) {
                anyTrue = true;
            }
        });

        if (anyTrue) {
            nav.classList.add("white");
        } else {
            nav.classList.remove("white");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollFunction);
        return () => window.removeEventListener("scroll", scrollFunction);
    }, []);

    // Language switch
    const { lang, setLang } = useContext(LanguageContext);
    const changeLang = () => {
        if (lang === "dutch") {
            setLang("english");
        } else {
            setLang("dutch");
        }
    };

    return (
        <div>
            <nav className="desk">
                <div className="tabs">
                    <JsA
                        href="/"
                        className={`tab home clickable ${
                            cur_page === "home" && "active"
                        }`}
                    >
                        <div className="logo"></div>
                    </JsA>
                    <JsA
                        href="/cases"
                        className={`tab clickable ${
                            cur_page === "cases" && "active"
                        }`}
                    >
                        <div className="title">{SiteContent[lang].nav[1]}</div>
                    </JsA>
                    <JsA
                        href="/over-ons"
                        className={`tab clickable ${
                            cur_page === "about" && "active"
                        }`}
                    >
                        <div className="title">{SiteContent[lang].nav[2]}</div>
                    </JsA>
                    <JsA
                        href="/contact"
                        className={`tab clickable ${
                            cur_page === "contact" && "active"
                        }`}
                    >
                        <div className="title">{SiteContent[lang].nav[3]}</div>
                    </JsA>
                    <div className="tab"></div>
                    <div className="tab"></div>
                    <div className="tab"></div>
                    <div className="tab">
                        <div className="switch">
                            <div className="lang">NL</div>
                            <label className="toggle">
                                <input
                                    type="checkbox"
                                    checked={lang === "english"}
                                    onChange={changeLang}
                                />
                                <span className="slider round"></span>
                            </label>
                            <div className="lang">EN</div>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className="mob">
                <div className="left">
                    <JsA href="/" className="tab">
                        <div className="logo"></div>
                    </JsA>
                </div>
                <div className="switch">
                    <div className="lang">NL</div>
                    <label className="toggle">
                        <input
                            type="checkbox"
                            checked={lang === "english"}
                            onChange={changeLang}
                        />
                        <span className="slider round"></span>
                    </label>
                    <div className="lang">EN</div>
                </div>
                <div className="burger-menu" onClick={() => setShow(!show)}>
                    <img src={menu} alt="" />
                </div>
                {show ? (
                    <div className="open">
                        <div className="items">
                            <img
                                src={close}
                                onClick={() => setShow(!show)}
                                className="close"
                                alt=""
                            />
                            <div className="item">
                                <JsA href="/">{SiteContent[lang].nav[0]}</JsA>
                            </div>
                            <div className="item">
                                <JsA href="/cases">
                                    {SiteContent[lang].nav[1]}
                                </JsA>
                            </div>
                            <div className="item">
                                <JsA href="/over-ons">
                                    {SiteContent[lang].nav[2]}
                                </JsA>
                            </div>
                            <div className="item">
                                <JsA href="/contact">
                                    {SiteContent[lang].nav[3]}
                                </JsA>
                            </div>
                        </div>
                    </div>
                ) : null}
            </nav>
        </div>
    );
}
