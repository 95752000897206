import React from 'react';
import '../../index.css';
import './content.css';

const Content = ({ img, title, text }) => (
    <div className="content white-block">
        <div className="inner">
            {/* <div className="side left sharpen"></div> */}
            <div className="side left harpen">
                {img ?
                    <img src={img} alt=""/>
                :
                    <div className="no-img"></div>
                }
            </div>
            <div className="side right">
                <h2>{title}</h2>
                <p>{text}</p>
            </div>
        </div>
    </div>
)

export default Content;

