import React, { useContext } from 'react';
import '../../index.css';
import './cases.css';

import SiteContent from '../../config/SiteContent';
import { LanguageContext } from '../../context/LanguageContextProvider';

import Nav from '../../components/base/nav/Nav';
import Hero from '../../components/hero/Hero';
import Filter from '../../components/filter/Filter';
import Thumbnail from '../../components/cases/thumbnail/Thumbnail';
import Footer from '../../components/base/footer/Footer';

export default function Cases() {
    const { lang } = useContext(LanguageContext);
    return (
        <div className="cases page">
            <Nav />
            <Hero
                small
                title={SiteContent[lang].cases.all.hero.title}
                text={SiteContent[lang].cases.all.hero.text}
            />
            <Filter all />
            <Thumbnail thumbnailData={SiteContent[lang].cases.all.thumbnails}/>
            <Footer/>
        </div>
    )
}
