import React from 'react'
import '../../index.css';
import './collage.css';

export default function Collage() {
    return (
        <div className="white-block collage-container">
        <div className="ratio white-block">

          <svg viewBox="0 0 7 3"></svg>
          <div className="box collage">
            <div className="inner">
                <div className="piece one"></div>
                <div className="piece two"></div>
                <div className="piece three"></div>
                <div className="piece four"></div>
                <div className="piece five">
                    <div className="logo"></div>
                </div>
                <div className="piece six"></div>
                <div className="piece seven"></div>
                <div className="piece eight"></div>
                <div className="piece nine"></div>
            </div>
        </div>
        </div>
        </div>
    )
}

