import React from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions.js";
import "../../../index.css";
import JsA from "../../base/jsa/JsA.js";
import "./thumbnail.css";

function Thumbnail({ thumbnailData }) {
    const { width } = useWindowDimensions();
    const renderThumbail = (thumbnail) => {
        return (
            <JsA href={`${thumbnail.url}`} className="inner reversed">
                <div className="img-side">
                    <img
                        className="img left"
                        src={thumbnail.image}
                        alt="NextGear"
                    />
                </div>
                <div className="text">
                    <div className="text-inner">
                        <h4>{thumbnail.name}</h4>
                        <h2>{thumbnail.title}</h2>
                        <div className="arrow"></div>
                    </div>
                </div>
                <div className="empty"></div>
            </JsA>
        );
    };
    const renderReversedThumbnail = (thumbnail) => {
        return (
            <JsA href={`${thumbnail.url}`} className="inner normal">
                <div className="empty"></div>
                <div className="text">
                    <div className="text-inner">
                        <h4>{thumbnail.name}</h4>
                        <h2>{thumbnail.title}</h2>
                        <div className="arrow"></div>
                    </div>
                </div>
                <div className="img-side">
                    <img
                        className="img right"
                        src={thumbnail.image}
                        alt="NextGear"
                    />
                </div>
            </JsA>
        );
    };
    return (
        <div className="thumbnail white-block">
            {thumbnailData.map((thumbnail, index) => {
                return width > 768 ? (
                    <div key={thumbnail.name}>
                        {index % 2 === 1
                            ? renderReversedThumbnail(thumbnail)
                            : renderThumbail(thumbnail)}
                    </div>
                ) : (
                    <div key={thumbnail.name}>{renderThumbail(thumbnail)}</div>
                );
            })}
        </div>
    );
}
export default Thumbnail;
