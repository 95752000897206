import React from 'react';
import '../../../index.css';
import './casehero.css';

const CaseHero = ({ client, title, type, image }) => (
    <div className="casehero">
        <div className="inner">
            <h3>{client}</h3>
            <h1>{title}</h1>
            <p>{type}</p>
        </div>
        <div className="container">
            <img src={image} alt="nextgear"/>
        </div>
    </div>
)
export default CaseHero;
