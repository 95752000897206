import React from "react";
import { useHistory } from "react-router";

/**
 *  A component which makes it easy to use regular <a> tags with JS navigation
 *  2022 - NextGear B.V - Jur Bruinink, Damiaan Houtschild
 *
 * @param {string} href - Where to link to
 * @param {string} className - CSS class name to be used to style <a> tag
 * @param {boolean} [disabled] - If true, the children are rendered without the wrapping <a> tag
 * @param {callback} [onClick] - Optional extra callback to be executed before the redirect
 * @param {object} [style] - Optional style object
 * @param {object} [redirState] - Optional state to pass on a JS redirect
 */

export default function JsA({
    href,
    className,
    children,
    disabled,
    onClick,
    style,
    redirState,
}) {
    const history = useHistory();

    const handleClick = (e) => {
        if (onClick) onClick();
        e.preventDefault();
        window.scrollTo({ top: 0 });
        history.push(href, redirState);
    };

    if (disabled) return children;

    return (
        <a
            href={href}
            className={className}
            onClick={handleClick}
            style={style}
        >
            {children}
        </a>
    );
}
