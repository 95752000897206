import React, {useState} from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions.js';
import './hero.css';
import '../../index.css';
import Tag from '../tag/Tag';
import Typed from 'react-typed';

export default function Hero({ title, text, typed1, typed2, typed3, typed4, small }) {
    const { width } = useWindowDimensions();

    const enter = ()=> {
        if (width < 768) {
            return <br/>
        }
    }

    const [typedColor, setTypedColor] = useState("var(--blue)");
    const nextColor = () => {
        if(typedColor === "var(--blue)"){
           setTypedColor("var(--orange)");
        }else if(typedColor === "var(--orange)"){
           setTypedColor("var(--green)");
        }else if (typedColor === "var(--green)"){
           setTypedColor("var(--pink)");
        }else if (typedColor === "var(--pink)"){
            setTypedColor("var(--blue)");
        }
    }

    return (
    <div>
        { small ?
            <div className="hero small">
                <div className="inner">
                    <div className="code">
                        <div className="typed">
                            <h1>
                                {title}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="hero">
                <div className="inner">
                    <div className="code">
                        <Tag type="h1" open/>
                        <div className="typed">
                            <h1>
                                {title}&nbsp;
                                {enter()}
                                {typed1 ?
                                    <Typed
                                        style={{color: typedColor}}
                                        strings={[typed1, typed2, typed3, typed4]}
                                        preStringTyped={() => nextColor()}
                                        typeSpeed={75}
                                        backSpeed={50}
                                        backDelay={1200}
                                        loop
                                    />
                                : null}
                            </h1>
                        </div>
                        <Tag type="h1" close/>
                        {text ?
                        <>
                            <Tag type="p" open/>
                            <h4>
                            {text}
                            </h4>
                            <Tag type="p" close/>
                        </>
                        : null }
                    </div>
                </div>
            </div>
        }
    </div>
    )
}
