import React, { useContext } from "react";
import "./filter.css";
import "../../index.css";

import SiteContent from "../../config/SiteContent";
import { LanguageContext } from "../../context/LanguageContextProvider";
import { Link } from "react-router-dom";

export default function Filter({ all, aviation, platforms, refactoring, strategy }) {
    const { lang } = useContext(LanguageContext);
    return (
        <div className="filter">
            <div className="inner">
                <div className="col">
                    <Link
                        to="/cases"
                        className={`button all ${all ? "active" : null}`}
                    >
                        {SiteContent[lang].cases.all.hero.title}
                    </Link>
                </div>
                <div className="col">
                    <Link
                        to="/aviation"
                        className={`button aviation ${aviation ? "active" : null}`}
                    >
                        {SiteContent[lang].cases.aviation.hero.title}
                    </Link>
                </div>
                <div className="col">
                    <Link
                        to="/platforms"
                        className={`button platforms ${
                            platforms ? "active" : null
                        }`}
                    >
                        {SiteContent[lang].cases.platforms.hero.title}
                    </Link>
                </div>
                <div className="col">
                    <Link
                        to="/refactoring"
                        className={`button refactoring ${
                            refactoring ? "active" : null
                        }`}
                    >
                        {SiteContent[lang].cases.refactoring.hero.title}
                    </Link>
                </div>
                <div className="col">
                    <Link
                        to="/strategy"
                        className={`button strategy ${
                            strategy ? "active" : null
                        }`}
                    >
                        {SiteContent[lang].cases.strategy.hero.title}
                    </Link>
                </div>
            </div>
        </div>
    );
}

// export default Filter
