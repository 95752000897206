import React, { useContext } from "react";
import "./footer.css";
import "../../../index.css";
import JsA from "../jsa/JsA";
import SiteContent from "../../../config/SiteContent";
import { LanguageContext } from "../../../context/LanguageContextProvider";

import carlo from "../../../assets/images/team/carlo.png";
import frederik from "../../../assets/images/team/frederik.png";

export default function Footer() {
    const { lang } = useContext(LanguageContext);

    return (
        <footer>
            <div className="inner">
                <div className="col left">
                    <div className="container">
                        <h3>{SiteContent[lang].footer.col1.title}</h3>
                        <p>
                            {SiteContent[lang].footer.col1.text}{" "}
                            <a
                                href={`tel:${SiteContent[lang].footer.col1.phone}`}
                            >
                                {SiteContent[lang].footer.col1.phone}
                            </a>
                        </p>
                    </div>
                </div>
                <div className="col center-left">
                    <img src={carlo} alt="Carlo de Cocq" />
                    <div className="container">
                        <h4>{SiteContent[lang].footer.col2.title}</h4>
                        <p>{SiteContent[lang].footer.col2.text}</p>
                        <a
                            href={`mailto:${SiteContent[lang].footer.col2.mail}`}
                        >
                            {SiteContent[lang].footer.col2.mail}
                        </a>
                    </div>
                </div>
                <div className="col center-right">
                    <img src={frederik} alt="" />
                    <div className="container">
                        <h4>{SiteContent[lang].footer.col3.title}</h4>
                        <p>{SiteContent[lang].footer.col3.text}</p>
                        <a
                            href={`mailto:${SiteContent[lang].footer.col3.mail}`}
                        >
                            {SiteContent[lang].footer.col3.mail}
                        </a>
                    </div>
                </div>
                <div className="col right">
                    <div className="container">
                        <h3>{SiteContent[lang].footer.col4.title}</h3>
                        <p>
                            {SiteContent[lang].footer.col4.text}{" "}
                            <JsA href={"/jobs"}>
                                {SiteContent[lang].footer.col4.page}
                            </JsA>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
