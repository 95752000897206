import React, { useContext } from 'react';
import '../../index.css';
import './about.css';

import SiteContent from '../../config/SiteContent';
import { LanguageContext } from '../../context/LanguageContextProvider';

import Nav from '../../components/base/nav/Nav';
import Hero from '../../components/hero/Hero';
import Content from '../../components/content/Content';
import Team from '../../components/team/Team';
import Footer from '../../components/base/footer/Footer';

import nextgear from '../../assets/images/collage/meeting.jpg';

export default function Home() {
    const { lang } = useContext(LanguageContext);

    return (
        <div className="about page">
            <Nav />
            <Hero
                title={SiteContent[lang].about.hero.title}
                typed1={SiteContent[lang].about.hero.typed1}
                typed2={SiteContent[lang].about.hero.typed2}
                typed3={SiteContent[lang].about.hero.typed3}
                typed4={SiteContent[lang].about.hero.typed4}
                text={SiteContent[lang].about.hero.text}
            />
            <Team />
            <Content
                img={nextgear}
                title={SiteContent[lang].about.content.title}
                text={SiteContent[lang].about.content.text}
            />
            <Footer />
        </div>
    )
}
