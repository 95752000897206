import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Home from "../pages/home/Home.js";
import About from "../pages/about/About.js";
import Contact from "../pages/contact/Contact.js";
import Cases from "../pages/cases/Cases.js";
import NotFoundPage from "../pages/errors/NotFoundPage.js";
import Avitms from "../pages/case/AviTMS.js";
import Finalyser from "../pages/case/Finalyser.js";
import FXDC from "../pages/case/FXDC.js";
import Kugis from "../pages/case/Kugis.js";
import Nextxs from "../pages/case/NextXS.js";
import TheNewWorker from "../pages/case/TheNewWorker.js";
import Voyp from "../pages/case/Voyp.js";
import Websites from "../pages/case/Websites.js";
import Jobs from "../pages/jobs/Jobs.js";
import JobBackender from "../pages/jobs/JobBackender.js";
import JobDesigner from "../pages/jobs/JobDesigner.js";
import JobPlatformEngineer from "../pages/jobs/jobPlatformEngineer.js";
import Platforms from "../pages/fundamentals/Platforms.js";
import Strategy from "../pages/fundamentals/Strategy.js";
import Refactoring from "../pages/fundamentals/Refactoring.js";
import Aviation from "../pages/fundamentals/Aviation.js";

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/cases" component={Cases} />
                <Route exact path="/avitms" component={Avitms} />
                <Route exact path="/finalyser" component={Finalyser} />
                <Route exact path="/fxdc" component={FXDC} />
                <Route exact path="/kugis" component={Kugis} />
                <Route exact path="/nextxs" component={Nextxs} />
                <Route exact path="/thenewworker" component={TheNewWorker} />
                <Route exact path="/voyp" component={Voyp} />
                <Route exact path="/websites" component={Websites} />
                <Route exact path="/aviation" component={Aviation} />

                <Route exact path="/platforms" component={Platforms} />
                <Route exact path="/refactoring" component={Refactoring} />
                <Route exact path="/strategy" component={Strategy} />
                <Route exact path="/over-ons" component={About} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/jobs" component={Jobs} />
                <Route exact path="/jobs/designer" component={JobDesigner} />
                <Route exact path="/jobs/backender" component={JobBackender} />
                <Route
                    exact
                    path="/jobs/platformengineer"
                    component={JobPlatformEngineer}
                />

                <Route path="*" component={NotFoundPage} />
            </Switch>
        </BrowserRouter>
    );
}
