import React, { useContext } from "react";
import Footer from "../../components/base/footer/Footer";
import Nav from "../../components/base/nav/Nav";
import "../../index.css";
import "./jobs.css";
import BulletInfo from "../../components/cases/info/BulletInfo";
import { LanguageContext } from "../../context/LanguageContextProvider";
import SiteContent from "../../config/SiteContent";
import Hero from "../../components/hero/Hero";
import Info from "../../components/cases/info/Info";

export default function JobDesigner() {
    const { lang } = useContext(LanguageContext);
    return (
        <div className="case page jobs">
            <Nav />
            <Hero
                title={SiteContent[lang].jobs.designer.title}
                text={SiteContent[lang].jobs.designer.text}
            />
            <BulletInfo
                title={SiteContent[lang].jobs.designer.description1.title}
                texts={Object.values(
                    SiteContent[lang].jobs.designer.description1.items
                )}
            />
            <BulletInfo
                noPaddingTop
                title={SiteContent[lang].jobs.designer.description2.title}
                texts={Object.values(
                    SiteContent[lang].jobs.designer.description2.items
                )}
            />
            <BulletInfo
                noPaddingTop
                title={SiteContent[lang].jobs.designer.description3.title}
                texts={Object.values(
                    SiteContent[lang].jobs.designer.description3.items
                )}
            />
            <BulletInfo
                noPaddingTop
                title={SiteContent[lang].jobs.designer.description4.title}
                texts={Object.values(
                    SiteContent[lang].jobs.designer.description4.items
                )}
            />
            <Info
                noPaddingTop
                title={SiteContent[lang].jobs.apply.title}
                text={SiteContent[lang].jobs.apply.text}
                email={<a href={"mailto:jobs@nextgear.nl"}>jobs@nextgear.nl</a>}
            />
            <Footer />
        </div>
    );
}
