import React from 'react';
import './member.css';
import '../../../index.css';

export default function Member({ image, name, job }){
    return(
        <div className="member jur" style={{ backgroundImage:`url(${image})` }}>
            <div className="member-inner">
                <div className="name">{name}</div>
                <div className="function">{job}</div>
            </div>
        </div>
    );
}
