import React, { useContext } from 'react';
import '../../index.css';
import './home.css';

import SiteContent from '../../config/SiteContent';
import { LanguageContext } from '../../context/LanguageContextProvider';

import Nav from '../../components/base/nav/Nav';
import Hero from '../../components/hero/Hero';
import Cols from '../../components/cols/Cols';
import Clients from '../../components/clients/Clients';
import Footer from '../../components/base/footer/Footer';

export default function Home() {
    const { lang } = useContext(LanguageContext);

    return (
        <div className="home page">
            <Nav />
            <Hero
                title={SiteContent[lang].home.hero.title}
                typed1={SiteContent[lang].home.hero.typed1}
                typed2={SiteContent[lang].home.hero.typed2}
                typed3={SiteContent[lang].home.hero.typed3}
                typed4={SiteContent[lang].home.hero.typed4}
                text={SiteContent[lang].home.hero.text}
            />
            <Cols
                colData={SiteContent[lang].home.col}
            />
            <Clients
                title={SiteContent[lang].home.clients.title}
            />
            <Footer />
        </div>
    )
}
