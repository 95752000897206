import React, {useContext} from 'react';
import '../../index.css';
import './contact.css';

import SiteContent from '../../config/SiteContent';
import { LanguageContext } from '../../context/LanguageContextProvider';

import Nav from '../../components/base/nav/Nav';
import Hero from '../../components/hero/Hero';
import Cols from '../../components/cols/Cols';
import Collage from '../../components/collage/Collage';
import Footer from '../../components/base/footer/Footer';

export default function Contact() {
    const { lang } = useContext(LanguageContext);
    return (
        <div className="contact page">
            <Nav />
            <Hero 
                title={SiteContent[lang].contact.hero.title}
                text={SiteContent[lang].contact.hero.text}
            />
            <Cols 
                colData={SiteContent[lang].contact.col}
            />
            <Collage />
            <Footer />
        </div>
    )
}
