import React from 'react';
import '../../../index.css';
import './image.css';

const Image = ({ image }) => (
    <div className="image">
        <div className="inner">
            <div className="container">
                <img src={image} alt=""/>
            </div>
        </div>
    </div>
)

export default Image;

