import React from 'react';
import '../../index.css';
import './tag.css';

const Tag = ({ open, close, type }) => (
    <div>
        { open &&
            <div className="tag">
                <div>&lt;</div>
                <div>{type}</div>
                <div>&gt;</div>
            </div>
        }
        { close &&
            <div className="tag">
                <div>&lt;</div>
                <div>&#x2f;</div>
                <div>{type}</div>
                <div>&gt;</div>
            </div>
        }
    </div>
)

export default Tag;
