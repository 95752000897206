import React, { useContext } from "react";
import Footer from "../../components/base/footer/Footer";
import Nav from "../../components/base/nav/Nav";
import "../../index.css";
import "./jobs.css";
import Info from "../../components/cases/info/Info";
import { LanguageContext } from "../../context/LanguageContextProvider";
import SiteContent from "../../config/SiteContent";
import Hero from "../../components/hero/Hero";
import Stefan from "../../assets/images/team/stefan.jpg";
import Quote from "../../components/cases/quote/Quote";
import JsA from "../../components/base/jsa/JsA";
export default function Jobs() {
    const { lang } = useContext(LanguageContext);
    return (
        <div className="case page jobs">
            <Nav />
            <Hero
                title={SiteContent[lang].jobs.hero.title}
                typed1={SiteContent[lang].jobs.hero.typed1}
                typed2={SiteContent[lang].jobs.hero.typed2}
                typed3={SiteContent[lang].jobs.hero.typed3}
                typed4={SiteContent[lang].jobs.hero.typed4}
                text={SiteContent[lang].jobs.hero.text}
            />
            {SiteContent[lang].jobs.header.title ? (
                <Info
                    title={SiteContent[lang].jobs.header.title}
                    text={SiteContent[lang].jobs.header.text}
                />
            ) : null}
            {SiteContent[lang].jobs.description1.title ? (
                <Info
                    noPaddingTop
                    title={SiteContent[lang].jobs.description1.title}
                    text={SiteContent[lang].jobs.description1.text}
                />
            ) : null}
            {SiteContent[lang].jobs.description2.title ? (
                <Info
                    noPaddingTop
                    title={SiteContent[lang].jobs.description2.title}
                    text={SiteContent[lang].jobs.description2.text}
                />
            ) : null}
            {SiteContent[lang].jobs.quote ? (
                <Quote
                    words={SiteContent[lang].jobs.quote.words}
                    author={SiteContent[lang].jobs.quote.author}
                    image={Stefan}
                    profession={SiteContent[lang].jobs.quote.profession}
                />
            ) : null}
            {SiteContent[lang].jobs.description3.title ? (
                <Info
                    title={SiteContent[lang].jobs.description3.title}
                    text={SiteContent[lang].jobs.description3.text}
                />
            ) : null}
            {SiteContent[lang].jobs.description4.title ? (
                <Info
                    noPaddingTop
                    title={SiteContent[lang].jobs.description4.title}
                    text={SiteContent[lang].jobs.description4.text}
                />
            ) : null}
            <JsA className={"link"} href={"/jobs/designer"}>
                {SiteContent[lang].jobs.designer.title ? (
                    <Info
                        noPaddingTop
                        title={SiteContent[lang].jobs.designer.title}
                    />
                ) : null}
            </JsA>
            <JsA className={"link"} href={"/jobs/backender"}>
                {SiteContent[lang].jobs.backender.title ? (
                    <Info
                        noPaddingTop
                        title={SiteContent[lang].jobs.backender.title}
                    />
                ) : null}
            </JsA>
            <JsA className={"link"} href={"/jobs/platformengineer"}>
                {SiteContent[lang].jobs.platformengineer.title ? (
                    <Info
                        noPaddingTop
                        title={SiteContent[lang].jobs.platformengineer.title}
                    />
                ) : null}
            </JsA>
            <Footer />
        </div>
    );
}
