import React from "react";
import "./cols.css";
import "../../index.css";
import JsA from "../base/jsa/JsA";
function Cols({ colData }) {
    var colList = [];

    colData.forEach((col, i) => {
        colList.push(
            <div key={i} className={`col ${col.theme}`}>
                {col.title ? <h2>{col.title}</h2> : null}
                {col.text ? <p>{col.text}</p> : null}
                {col.mail ? (
                    <a href={`mailto:${col.mail}`}>{col.mail}</a>
                ) : null}
                {col.phone ? (
                    <a href={`tel:${col.phone}`}>{col.phone}</a>
                ) : null}
                {col.link ? (
                    <a href={`${col.linkUrl}`} target="_blank" rel="noreferrer">
                        {col.link}
                    </a>
                ) : null}
                {col.button ? (
                    <JsA href={`${col.buttonUrl}`} className="button">
                        {col.button}
                    </JsA>
                ) : null}
            </div>
        );
    });

    return (
        <div className="cols white-block">
            <div className="inner">{colList}</div>
        </div>
    );
}

export default Cols;
