import thumbnailNextXS from "../assets/images/cases/thumbnails/nextxs2.png";
import thumbnailFinalyser from "../assets/images/cases/thumbnails/finalyser2.png";
import thumbnailAviTMS from "../assets/images/cases/thumbnails/avitms.png";
import thumbnailFXDC from "../assets/images/cases/thumbnails/fxdc.png";
import thumbnailKugis from "../assets/images/cases/thumbnails/kugis2.png";
import thumbnailWebsites from "../assets/images/cases/thumbnails/websites2.png";
import thumbnailTheNewWorker from "../assets/images/cases/thumbnails/thenewworker.png";
import thumbnailVoyp from "../assets/images/cases/thumbnails/voyp.png";

export default {
    dutch: {
        nav: ["Home", "Cases", "Over ons", "Contact"],
        footer: {
            col1: {
                title: "Samen werken aan jouw digitale kanalen?",
                text: "Laten we aan de slag gaan met jouw uitdaging!\n\nBel ons op",
                phone: "020-7881300",
            },
            col2: {
                title: "New business?",
                text: "Mail Carlo op ",
                mail: "carlo@nextgear.nl",
            },
            col3: {
                title: "Samenwerken?",
                text: "Mail Frederik op ",
                mail: "frederik@nextgear.nl",
            },
            col4: {
                title: "Kom je ook voor ons werken?",
                text: "Kijk naar onze openstaande vacatures op ",
                page: "jobs",
            },
        },
        home: {
            hero: {
                title: "Wij bouwen",
                typed1: "platformen",
                typed2: "apps",
                typed3: "websites",
                typed4: "koppelingen",
                text: "NextGear werkt al meer dan 20 jaar aan duurzame ICT oplossingen voor vandaag en morgen.",
            },
            col: [
                {
                    title: "Platformen",
                    text: "Meer overzicht en tijd dankzij slimmere software.",
                    button: "Meer",
                    buttonUrl: "/platforms",
                },
                {
                    title: "Refactoring",
                    text: "Verouderde systemen een nieuw, gebruiksvriendelijk leven inblazen.",
                    button: "Meer",
                    buttonUrl: "/refactoring",
                },
                {
                    title: "Strategie",
                    text: "Samen met NextGear jouw bedrijfsprocessen verbeteren.",
                    button: "Meer",
                    buttonUrl: "/strategy",
                },
            ],
            clients: {
                title: "Wij werken met",
            },
            content: {
                title: "Jarenlange ervaring",
                text: "Al meer dan 20 jaar bouwt NextGear aan digitale oplossingen voor veelzijdige opdrachtgevers uit verschillende branches. Al meer dan 20 jaar bouwt NextGear aan digitale oplossingen voor veelzijdige opdrachtgevers uit verschillende branches. Al meer dan 20 jaar bouwt NextGear aan digitale oplossingen.",
            },
        },
        cases: {
            all: {
                hero: {
                    title: "Alle cases",
                },
                thumbnails: [
                    {
                        name: "NextXS",
                        title: "De sleutel naar de toekomst.",
                        image: thumbnailNextXS,
                        url: "/nextxs",
                    },
                    {
                        name: "Voyp",
                        title: "Facturatie met 1 druk op de knop.",
                        image: thumbnailVoyp,
                        url: "/voyp",
                    },
                    {
                        name: "Finalyser",
                        title: "Overzicht in al je administraties.",
                        image: thumbnailFinalyser,
                        url: "/finalyser",
                    },
                    {
                        name: "Kugis",
                        title: "State of the art software voor het uitlenen van kunst.",
                        image: thumbnailKugis,
                        url: "/kugis",
                    },
                    {
                        name: "AviTMS",
                        title: "Het complete trainingsboeking- systeem.",
                        image: thumbnailAviTMS,
                        url: "/avitms",
                    },
                    {
                        name: "The New Worker",
                        title: "Flexibele werkplekken nog flexibeler.",
                        image: thumbnailTheNewWorker,
                        url: "/thenewworker",
                    },
                    {
                        name: "FXDC",
                        title: "Gaat verder waar reguliere pakketbezorging stopt.",
                        image: thumbnailFXDC,
                        url: "/fxdc",
                    },
                    {
                        name: "Nextgear Fastlane",
                        title: "Prachtige presence websites in een fractie van tijd.",
                        image: thumbnailWebsites,
                        url: "/websites",
                    },
                ],
            },
            platforms: {
                hero: {
                    title: "Platformen",
                },
                thumbnails: [
                    {
                        name: "Voyp",
                        title: "Facturatie met 1 druk op de knop",
                        image: thumbnailVoyp,
                        url: "/voyp",
                    },
                    {
                        name: "Finalyser",
                        title: "Overzicht in al je administraties",
                        image: thumbnailFinalyser,
                        url: "/finalyser",
                    },
                    {
                        name: "The New Worker",
                        title: "Flexibele werkplekken nog flexibeler",
                        image: thumbnailTheNewWorker,
                        url: "/thenewworker",
                    },
                    {
                        name: "NextXS",
                        title: "De sleutel naar de toekomst",
                        image: thumbnailNextXS,
                        url: "/nextxs",
                    },
                ],
            },
            aviation: {
                hero: {
                    title: "Luchtvaart",
                },
                thumbnails: [
                    {
                        name: "AviTMS",
                        title: "Het complete trainingsboekingsysteem",
                        image: thumbnailAviTMS,
                        url: "/avitms",
                    },
                ],
            },
            strategy: {
                hero: {
                    title: "Strategie",
                },
                thumbnails: [
                    {
                        name: "Kugis",
                        title: "State of the art software voor het uitlenen van kunst",
                        image: thumbnailKugis,
                        url: "/kugis",
                    },
                    {
                        name: "FXDC",
                        title: "Gaat verder waar reguliere pakketbezorging stopt",
                        image: thumbnailFXDC,
                        url: "/fxdc",
                    },
                ],
            },
            refactoring: {
                hero: {
                    title: "Refactoring",
                },
                thumbnails: [
                    {
                        name: "Kugis",
                        title: "State of the art software voor het uitlenen van kunst",
                        image: thumbnailKugis,
                        url: "/kugis",
                    },
                    {
                        name: "Nextgear Fastlane",
                        title: "Prachtige presence websites in een fractie van tijd",
                        image: thumbnailWebsites,
                        url: "/websites",
                    },
                ],
            },
        },
        case: {
            nextxs: {
                casehero: {
                    client: "NextXS",
                    title: "De sleutel naar de toekomst",
                    type: "Design / App / Hardware / Web development",
                },
                info_1: {
                    title: "Bijzondere software voor prachtige hardware",
                    text: "Next XS is een open platform voor moderne toegangscontrole: centraal toegangsbeheer, sloten die niet aan het internet zijn verbonden, hardware agnostisch en eenvoudig in gebruik. Middels de API zijn diverse koppelingen mogelijk. NextGear is verantwoordelijk voor de technische strategie en de ontwikkeling. Dit betreft onder andere: API, diverse mobiele apps voor bijvoorbeeld sleutelbeheer en configuratie, de webomgeving voor het beheer van toegang en de firmware voor de sloten. en andere systemen.",
                },
                info_2: {
                    title: "Veiligheid zonder gedoe",
                    text: "Next XS ontzorgt klanten met het door NextGear ontwikkelde platform op het gebied van het veilig kunnen ontsluiten van elke denkbare ruimte in meerdere gebouwen. Gebruikers hebben maar één account nodig om bij meerdere organisaties toegang te krijgen tot ruimten in hun kantoren. Het is heel flexibel, schaalbaar maar ook niet onbelangrijk erg veilig. Moderne security op basis van bewezen versleutelingsalgoritmen zorgt ervoor dat je de deur met een gerust hart achter je dicht kan trekken.",
                },
                info_3: {
                    title: "XS Alert",
                    text: "Het flexibele platform van Next XS heeft ook de aandacht van de zorg. Met partners als Kepler en Hikvision wordt er stevig geïnnoveerd. XS Alert is ontwikkeld om zorgmeldingen op een betrouwbare manier door te geleiden naar de verantwoordelijke zorgverlener. Door deze ontwikkeling kan er door middel van de hardware en software van bijvoorbeeld Hikvision en Kepler een zorgmelding worden verstuurd indien er patient uit bed is gevallen. Vanzelfsprekend is XS Alert net zo flexibel ontworpen als Next XS. Het systeem kan een veelheid van zorgmeldingen ontvangen en doorgeleiden. Door middel van het XS Alert Endpoint kan er eenvoudig worden geïntegreerd met het zorgmeldingen systeem. Onze oplossingen zijn veilig, technisch agnostisch en schaalbaar. Veel klanten kiezen voor een implementatie op maat, een partnership waarin we samenwerken aan de beste oplossing; voor nu en morgen.",
                },
                quote: {
                    words: "De samenwerking met NextGear heb ik als uiterst prettig ervaren. Wij zijn geen makkelijke klant, in die zin dat wij hoge eisen stellen aan onze partners.",
                    author: "Michael van den Burgt",
                    profession: "Manager Marketing & Communication bij NextXS",
                },
            },
            voyp: {
                casehero: {
                    client: "Voyp",
                    title: "Facturatie met 1 druk op de knop",
                    type: "Design / Koppeling / Web development",
                    image: thumbnailVoyp,
                },
                info_1: {
                    title: "Tijdbesparende koppeling",
                    text: "Voyp levert zakelijke telefonie. De hele workflow van hun dienstverlening wordt afgehandeld in een CRM. Hierin landt al het klantcontact, bestellingen van hardware, de samenstelling van het abonnement en wat dies meer zij. Elke eerste maandag van de maand gaan de facturen er uit die samengesteld zijn uit diverse onderdelen. Een tijdrovende en foutgevoelige operatie die met de hand werd uitgevoerd.",
                },
                info_2: {
                    title: "Van CRM-systeem direct in je administratie",
                    text: "NextGear heeft de hele facturatie geautomatiseerd. De oplossing haalt uit de benodigde databronnen de benodigde informatie en toont een totalisering van de facturen op onderdelen die snel zijn te controleren. Is dit in orde dan worden er conceptfacturen in het boekhoudpakket aangemaakt. Deze zijn nog eenvoudig te verwijderen mochten de geraadpleegde databronnen nog niet helemaal zijn bijgewerkt. Is alles goed dan kunnen alle facturen eenvoudig worden goedgekeurd en digitaal worden uitgestuurd met een druk op de knop.",
                },
                quote: {
                    words: "’Dankzij de oplossing van NextGear is het facturatieproces van een dagenlang en hemeltergend saai proces vereenvoudigt en volledig geautomatiseerd. De foutgevoeligheid is hiermee enorm gedaald.",
                    author: "Michael Jansen",
                    profession: "Co founder bij Voyp",
                },
            },
            finalyser: {
                casehero: {
                    client: "Finalyser",
                    title: "Overzicht in je administraties",
                    type: "Design / Koppeling Web / development",
                    image: thumbnailFinalyser,
                },
                info_1: {
                    title: "De eerste slimme Moneybird koppeling",
                    text: "NextFin is een financieel administratief kantoor gespecialiseerd in het boekhoudpakket Moneybird. Moneybird biedt een zeer gebruiksvriendelijk pakket aan met veel mogelijkheden. Echter, het is lastig inzichtelijk hoe gezond je administratie is: hoeveel transacties moet ik nog boeken? Ontbreken er bonnetjes? Heb ik wel goed geboekt? Kan ik nu met een gerust hart de BTW-aangifte indienen?",
                },
                info_2: {
                    title: "Bonnetjesbingo",
                    text: "Voor boekhoudkantoren die klanten met Moneybird ondersteunen is deze uitdaging nog groter. Het is voor hen belangrijk tijdig te weten welke administraties nog veel aandacht behoeven zodat ze het werk goed kunnen indelen. Bovendien is het nuttig tijdig te signaleren dat de klant nog de nodige bonnetjes moet aanleveren.",
                },
                info_3: {
                    title: "Sneller, Beter, Netter",
                    text: "Finalyser biedt een scala aan powertools om dit mogelijk te maken. In een oogopslag zie je in welke administraties het goed gaat en waar minder. Als de fiscale maand dichterbij komt kun je tijdig in beeld brengen welke bonnetjes nog moeten worden opgespoord voor een correcte BTW aangifte. Finalyser is in hoge mate te configureren. Zo kun je op grootboekniveau allerlei controles activeren die je helpen nauwkeuriger en sneller te boekhouden.",
                },
                quote: {
                    words: "Het is bijna niet meer mogelijk om fouten te maken met Finalyser, corrigeren gaat ontzettend snel.",
                    author: "Thiemo Stougie",
                    profession: "Founder ROAR! Concepts",
                },
            },
            avitms: {
                casehero: {
                    client: "AviTMS",
                    title: "Het complete trainingsboekingsysteem",
                    type: "Web development / Koppeling",
                    image: thumbnailAviTMS,
                },
                info_1: {
                    title: "van A tot Z",
                    text: "Sinds 2006 is NextGear actief in de luchtvaartbranche. Voor in de luchtvaart gespecialiseerde trainingsorganisaties is er door ons een uitgebreid training management systeem ontwikkeld. Met behulp van onze software kunnen zij de volledige workflow betrouwbaar en conform internationale standaarden afhandelen.",
                },
                info_2: {
                    title: "Integrale oplossing",
                    text: "De software biedt mogelijkheden voor: registratie van cursisten, management van het cursusmateriaal, testen, examineren en certificering, instructeurs indeling, klaslokaal management, volledige boekhouding van cursisten en instructeurs, interne en externe audits en kwaliteitsmanagement (KPI’s).",
                },
                info_3: {
                    title: "De wereld over",
                    text: "Een divers aantal klanten in binnen en buitenland maakt gebruik van deze oplossing. De software wordt gebruik in Nederland, Duitsland, Spanje en Casablanca. De software is ontworpen met het oog op de strenge eisen die de International Civil Aviation Organization stelt.",
                },
                quote: {
                    words: "The ITMS is not only an excellent centralized system into which all our processes are integrated but also a fantastic managerial tool supporting our successful growing activities as Training Center of Excellence. We are therefore pleased to make this potent integrated training management system available, trough Software Development Company NextGear BV, to training organisations committed to optimal efficient performance in delivering training courses ultimately based on high-quality international standards.",
                    author: "Paula de Almeida",
                    profession: "Director Jaato",
                },
            },
            kugis: {
                casehero: {
                    client: "Kugis",
                    title: "State of the art software voor het uitlenen van kunst",
                    type: "Een aantal organisaties in de kunstuitleen werd geconfronteerd met een bijzondere uitdaging. De software die door hen werd gebruikt was verouderd en kon lastig worden geüpdatet. Om deze uitdaging op te lossen is er een inkooporganisatie opgericht. Ze hebben wensen opgesteld en zijn hiermee de markt opgegaan. Na de nodige gesprekken hebben ze gekozen voor NextGear om dit nieuwe systeem te realiseren.",
                },
                info_1: {
                    title: "Uitlenen of kopen? Geen probleem!",
                    text: "Een kunstuitleen wil vrij zijn in hoe kunst wordt uitgeleend, maar wel voldoen aan alle regelgeving. Dit houdt in dat de module voor de abonnementen deze vrijheid moet bieden, maar ook inzicht en controlemiddelen moet geven. Zo kan er sprake zijn van speciale abonnementen voor bepaalde doelgroepen, flexibele kortingen, wisselende werkelijkheden rondom fiscaliteit of het, aankopen van aan de huur van kunst gerelateerde accessoires. \n\n Ook is het de bedoeling dat er een uitgebreide kunstcatalogus kan worden opgebouwd die ook via het internet kan worden geraadpleegd. Alle processen in de organisatie worden afgehandeld via dit nieuwe pakket. Met de nieuwe oplossing wordt het verhuren een koud kunstje en verbetert de mate waarin efficiënt kan worden voldaan aan accountability enorm.",
                },
                quote: {
                    words: "...",
                    author: "...",
                    profession: "...",
                },
            },
            thenewworker: {
                casehero: {
                    client: "The New Worker",
                    title: "Flexibele werkplekken nog flexibeler",
                    type: "Design / Koppeling Web / development",
                    image: thumbnailTheNewWorker,
                },
                info_1: {
                    title: "Laat onroerend goed werken",
                    text: "The New Worker levert oplossingen voor eigenaren van onroerend goed, vastgoedbeheerders en facility managers. Door het platform van The New Worker kunnen (leegstaande) kantoorruimten en werkplekken eenvoudig worden gedeeld binnen bedrijven of tussen aangesloten bedrijven onderling.",
                },
                info_2: {
                    title: "Meer reseveringen",
                    text: "De deelnemers aan TNW kunnen eenvoudig zien waar werkplekken beschikbaar zijn, welke faciliteiten aanwezig zijn en kunnen de werkplek eenvoudig reserveren. Op de dag van de reservering ontvangt de deelnemer instructies (van route tot wifi gegevens) en een digitale sleutel. De deelnemer checkt in, gaat zitten en kan aan de slag.",
                },
                info_3: {
                    title: "Toegang en overzicht",
                    text: "NextGear heeft zowel het boekings en managementplatform gerealiseerd als de koppeling met de API van NextXS waardoor het genereren en sturen van een digitale toegang tot de werkplek volledig is geïntegreerd. Voor de verhuurder zijn statistieken over het gebruik beschikbaar en de werkgever kan zien wie in welke mate van de dienst gebruik maakt.",
                },
                info_4: {
                    title: "Makkelijke implementatie",
                    text: "Het platform voor TNW wordt door henzelf geëxploiteerd maar is ook zo ontwikkeld dat de software in aangepaste vorm door andere organisaties kan worden hergebruikt. De uitdagingen in werkplekken zijn door de pandemie immers legio en weer op een andere manier aanwezig bij landelijk opererende bedrijven en/of organisaties in flexplek verhuur.",
                },
                quote: {
                    words: "",
                    author: "",
                    profession: "",
                },
            },
            fxdc: {
                casehero: {
                    client: "FXDC",
                    title: "Gaat verder waar reguliere pakketbezorging stopt",
                    type: "Web development / Koppeling",
                },
                info_1: {
                    title: "Bijzondere dienstverlening",
                    text: "FXDC is een onderneming met kantoren op Bonaire, St. Eustatius en Saba. Ze voorzien diverse klanten van bijzondere dienstverlening op het gebied van brief- en pakketpost zoals postbussen, internationale zendingen met prioriteit en pakketverzending.",
                },
                info_2: {
                    title: "Waar is mijn pakketje?",
                    text: "Klanten van FXDC Europoint willen graag kunnen webwinkelen in Europa. Echter, deze leveren niet aan de eilanden. Via de door NextGear ontwikkelde portal van FXDC Europoint kunnen klanten hun e-commerce bestellingen aanmelden, laten bezorgen op een drop off point van FXDC Europoint, welke de zending vervolgens bij hen aflevert. Klanten kunnen hun zendingen uiteraard keurig volgen via track & trace. FXDC kan de status van de zendingen muteren en alle benodigde administratieve processen bijhouden in dit portaal. De oplossing is flexibel.Zo zorgen we ervoor dat de software is voorbereid op toekomstige aanpassingen.",
                },
                quote: {
                    words: "",
                    author: "",
                    profession: "",
                },
            },
            websites: {
                casehero: {
                    client: "NextGear Fastlane",
                    title: "Prachtige presence websites in een fractie van tijd",
                    type: "Design / Web ontwikkeling",
                },
                info_1: {
                    title: "Korte doorlooptijden, duidelijke tarieven",
                    text: "In het begin van 2020 zijn we als bureau officieel partner geworden van WiX en sindsdien ontwikkelen we alle relatief kleinschalige websites met de WiX Editor. We bedachten een bijbehorende werkwijze die we Fastlane noemen. Fastlane ziet erop toe dat we onze klanten in een korte doorlooptijd kunnen voorzien van een nieuwe en moderne website via een overzichtelijke werkwijze en heldere tarieven. Zo weten onze klanten direct waar ze aan toe zijn, wel zo prettig.",
                },
                info_2: {
                    title: "WIX Editor",
                    text: "Om zo snel en efficiënt te kunnen werken gebruiken we voor de ontwikkeling van deze websites de WiX Editor. Hiermee kunnen onze ontwikkelaars in een hoog tempo complete websites opleveren die ook nog eens eenvoudig door ons kunnen worden beheerd en aangepast. Ook stelt de tool ons in staat om deze websites tegen een voordelig tarief als alles-in-één oplossing aan te bieden zodat je als ondernemer je geen zorgen hoeft te maken over het onderhoud, de hosting of bijvoorbeeld de analytics van de website en dat je meer tijd overhoudt voor de zaken die er toe doen.",
                },
                info_3: {
                    title: "Tarieven",
                    text: "Per aanvraag doen wij een duidelijk voorstel dat gebaseerd is op een aantal pagina’s. We werken een concept ontwerp uit, nadat is geaccepteerd werken wij de resterende pagina’s uit. Op elke pagina heb je twee correctieronden. Op deze manier kan rechtstreekse feedback worden verwerkt, maar kunnen ook nieuwe inzichten die tijdens het project zijn opgekomen in de laatste fase nog worden meegenomen voordat de site de lucht in gaat. Mochten er hierna nog wensen zijn, dan werken wij met voordelige strippenkaarten om dit onderhoud mee uit te voeren.",
                },
                info_4: {
                    title: "Zij gingen je voor",
                    text: "Voorbeelden van websites die met Fastlane tot stand zijn gekomen zijn bijvoorbeeld: ducot.nl, finalyser.nl en strijkenzet.nl",
                },
                quote: {
                    words: "...",
                    author: "...",
                    profession: "...",
                },
            },
        },
        about: {
            hero: {
                title: "Wij zijn",
                typed1: "developers",
                typed2: "designers",
                typed3: "strategen",
                typed4: "partners",
                text: "Bij Nextgear denken we graag met je mee. Wij zijn in staat jouw ideeën om te zetten in een werkend product. Wil je erachter komen wat we voor jou kunnen doen? Kom eens langs bij ons op kantoor!",
            },
            ourTeam: {
                title: "Ons team",
            },
            member: [
                {
                    name: "Jur",
                    job: "Developer",
                },
                {
                    name: "Stefan",
                    job: "Developer",
                },
                {
                    name: "Lisanne",
                    job: "Designer/Developer",
                },
                {
                    name: "Jochem",
                    job: "Head of Tech",
                },
                {
                    name: "Jean-Micheal",
                    job: "Head of Finance/Operations ",
                },
                {
                    name: "Remy",
                    job: "Project Manager/Developer",
                },
                {
                    name: "Frederik",
                    job: "Founder of NextGear",
                },
                {
                    name: "Bram",
                    job: "Operations/Legal",
                },
                {
                    name: "Pim",
                    job: "Management Assistent",
                },
                {
                    name: "Vincent",
                    job: "Developer",
                },
                {
                    name: "Tarquin",
                    job: "Developer",
                },
                {
                    name: "Monster",
                    job: "Kantoorhond",
                },
            ],
            content: {
                title: "20 Jaar ervaring",
                text: "Al meer dan 20 jaar bouwt NextGear aan digitale oplossingen voor veelzijdige opdrachtgevers uit verschillende branches.",
            },
        },
        contact: {
            hero: {
                title: "Leuk je te ontmoeten",
                text: "Heb je een goed idee, een technische uitdaging of wil je gewoon een kopje koffie komen drinken? Geen probleem! Kom langs bij ons in ons kantoor in het centrum van Amsterdam.",
            },
            col: [
                {
                    title: "Contact",
                    text: "Wij staan voor je klaar",
                    mail: "info@nextgear.nl",
                    phone: "020-7881300",
                },
                {
                    title: "Adres",
                    text: "Nieuwe Herengracht 123a \n 1011SC Amsterdam",
                    link: "Google maps",
                    linkUrl:
                        "https://www.google.nl/maps/place/Nieuwe+Herengracht+123A,+1011+SC+Amsterdam/@52.3684899,4.9060597,17z/data=!3m1!4b1!4m5!3m4!1s0x47c609a2d498994d:0x6dad297d23035a5f!8m2!3d52.3684899!4d4.9082484",
                },
                {
                    title: "Social",
                    text: "Volg ons ook op",
                    link: "LinkedIn",
                    linkUrl: "https://www.linkedin.com/company/nextgear/",
                },
            ],
        },
        jobs: {
            hero: {
                title: "Kom bij ons",
                typed1: "werken",
                typed2: "groeien",
                typed3: "coden",
                typed4: "koffie drinken",
                text: "Sluit je aan bij ons innovatieve team en breng jouw ideeën tot leven. Ontdek onze huidige mogelijkheden en ontdek hoe je een deel kunt worden van ons avontuur.",
            },

            header: {
                title: "Gezocht: een getalenteerde ontwerper, een bekwame backend ontwikkelaar en een ervaren platform engineer",
                text: "We zijn op zoek naar drie mensen om ons NextGear team te versterken. We zijn hard aan het werk en hebben extra handen nodig om het momentum vast te houden. Daar hebben we jou voor nodig!",
            },
            description1: {
                title: "Wie zijn wij?",
                text: "NextGear is opgericht in 2003. Lang hebben we voor klanten gewerkt, maar onlangs hebben we onze focus verlegd naar het bouwen van ons eigen product. We zijn trots op ons team van acht gemotiveerde en jonge tech liefhebbers tussen de 20 en 40 jaar oud, die werken aan alles van design en frontend tot backend en infrastructuur. Ons spannende nieuwe platform, dat vorig jaar is gelanceerd, heeft al onze verwachtingen overtroffen en dit heeft ervoor gezorgd dat we ons team moeten uitbreiden om het platform naar een hoger niveau te tillen.",
            },
            description2: {
                title: "Wat is de baan?",
                text: "Kort gezegd: we zijn op zoek naar een ontwerper, een backend ontwikkelaar en een platform engineer die ons kunnen helpen ons platform te verbeteren. Na een intensieve periode, waar het belangrijkste doel was om zo snel mogelijk het platform te lanceren, zijn we nu gericht op het verbeteren van het platform. Dit geldt zowel voor de UI als voor alles achter de schermen. In dit proces besteden we aandacht aan beveiliging en betrouwbaarheid vanwege de hoeveelheid geld die via het platform wordt verwerkt. Ook letten we op gebruiksvriendelijkheid en prestaties, aangezien we tot wel 10.000 backend-verzoeken per minuut ontvangen, een aantal dat blijft stijgen.",
            },
            description3: {
                title: "Waar?",
                text: "We zijn een hybride-remote team dat graag flexibel is. Ons doel is om elkaar minstens één keer per week te zien, omdat we zo op elkaar afgestemd blijven. Je kunt vanuit huis of vanuit ons kantoor in het hart van Amsterdam werken. We zijn een diverse groep mensen die hard werken, maar ook tijd hebben om plezier te maken. We bieden een Nederlandse lunch - of Surinaams eten op de eerste maandag van elke maand, en we hebben een uitstekende espressomachine voor koffieliefhebbers (maar maak je geen zorgen, we hebben ook thee). We bieden een veilige, open en transparante omgeving, en af en toe hebben we een harige vriend op kantoor.",
            },
            description4: {
                title: "Geef me wat details...",
                text: "Voor elke functie hebben we een gedetailleerdere beschrijving hieronder. Op dit moment ligt onze focus op het geleidelijk vervangen van een frontend geschreven in Django door een frontend gebaseerd op React. Dit is waarom de laatste mensen die ons in 2022 zijn komen versterken beiden frontend ontwikkelaars waren.\nDe nieuwe frontend stelt ons in staat om verder op te schalen. Deze frontend verbindt met een API die we toevoegen aan het bestaande Django-project, waarna de bestaande frontend-code uit het Django-project wordt verwijderd. Ondertussen schrijven we nieuwe functionaliteit in gewoon Django, die later mogelijk naar React kan worden overgezet. Het omschrijven van Django naar React biedt ons ook de mogelijkheid om te reflecteren op en de huidige vormgeving en de bruikbaarheid te verbeteren.",
            },

            quote: {
                words: "Als ontwikkelaar bij NextGear zet ik ideeën om in realiteit. Ik ben trots om deel uit te maken van dit dynamische team dat innovatief is en mij in staat stelt een betekenisvolle impact te maken in de wereld van softwareontwikkeling.",
                author: "Stefan",
                profession: "Ontwikkelaar",
            },
            apply: {
                title: "Hoe solliciteren?",
                text: "Om te solliciteren, stuur je je cv en motivatie per e-mail naar:",
            },

            designer: {
                title: "> Designer",
                text: "Ontketen je creatieve genie! Sluit je aan bij ons team als ontwerper en creëer visueel verbluffende ontwerpen die onze gebruikers aanspreken en ons merk naar nieuwe hoogten tilt.",
                description1: {
                    title: "Je werkzaamheden bestaan uit:",
                    items: {
                        1: "Websiteontwerp (en frontend) voor NextGear-klanten",
                        2: "Kleinere grafische opdrachten zoals logo's, promotiematerialen voor sociale media of een roll-up banner",
                        3: "Ontwerpen van oplossingen voor UI/UX-problemen binnen onze producten",
                        4: "Brainstormsessies met collega's om onze producten te verbeteren",
                    },
                },
                description2: {
                    title: "Ideaal gezien beschik je over de volgende vaardigheden:",
                    items: {
                        1: "Ervaring met Adobe (voornamelijk Adobe XD, Illustrator en Photoshop)",
                        2: "Hiermee val je op: kennis van frontend-technieken (bijv. HTML/CSS/JS en relevante tools of frameworks)",
                    },
                },
                description3: {
                    title: "Wat we echt nodig hebben:",
                    items: {
                        1: "Iemand met een goed gevoel voor ontwerp",
                        2: "Iemand die de werkdruk kan verlichten en een geweldige sparringpartner kan zijn",
                        3: "Iemand die daadwerkelijk een bijdrage kan leveren",
                    },
                },
                description4: {
                    title: "Maak je geen zorgen over:",
                    items: {
                        1: "18 jaar ervaring hebben, we zijn niet op zoek naar een veteraan",
                    },
                },
            },

            backender: {
                title: "> Backender",
                text: "Geef het web kracht met je expertise in back-end ontwikkeling! Sluit je aan bij ons team als een back-end ontwikkelaar en vorm de toekomst van ons platform met baanbrekende technologieën en spannende projecten.",
                description1: {
                    title: "Je werkzaamheden bestaan uit:",
                    items: {
                        1: "Werken aan nieuwe functies voor bestaande projectonderdelen",
                        2: "Verantwoordelijk zijn voor native Django-weergaven (model/view/template) en/of admin",
                        3: "REST-framework-endpoints",
                        4: "verbindingen leggen met bestaande API's voor betalingsproviders en andere derde partijen",
                        5: "Schrijven van (nieuwe) microservices (plain Python of Flask)",
                        6: "Werken aan datascience-gerelateerde taken",
                        7: "Beoordelen van merge requests",
                        8: "Refactoren en schrijven van tests",
                        9: "CI-configuratie",
                        10: "...eerlijk gezegd zijn er veel dingen die we willen doen, we zitten boordevol plannen en ideeën",
                    },
                },
                description2: {
                    title: "Ideaal gezien beschik je over de volgende vaardigheden:",
                    items: {
                        1: "Ervaring met Python/Django (we proberen PEP8 te volgen)",
                        2: "Kennis van gangbare design patterns",
                        3: "Dit zou je doen opvallen: ervaring met projecten die draaien op AWS (bijv. implementatie van S3 of gebruik van Rekognition)",
                    },
                },
                description3: {
                    title: "Wat we echt nodig hebben:",
                    items: {
                        1: "Een ervaren programmeur",
                        2: "In staat zijn om buiten de kaders van je eigen taken te denken",
                    },
                },
                description4: {
                    title: "Maak je geen zorgen over:",
                    items: {
                        1: "Niet alles weten van Django als je ervaring hebt met Python",
                        2: "je jezelf alleen in enkele van de bovengenoemde activiteiten herkent.",
                    },
                },
            },

            platformengineer: {
                title: "> Platform engineer",
                text: "Revolutioneer ons platform als een platform engineer! Sluit je aan bij ons team om cutting-edge systemen te ontwerpen en implementeren die naadloze gebruikerservaringen mogelijk maken en de toekomst vormgeven van ons innovatieve platform.",
                description1: {
                    title: "Uw activiteiten bestaan uit:",
                    items: {
                        1: "De dagelijkse operaties: updates, monitoring, beveiliging, het detecteren van anomalieën",
                        2: "Beheren van een platform dat is verspreid over meerdere AWS-accounts",
                        3: "Orde op zaken stellen",
                        4: "Staging- en productie-omgevingen in sync houden",
                        5: "Opzetten en beheren van een dataplatform",
                        6: "Platformbrede gecentraliseerde logging met behulp van syslogd",
                        7: "Grafana-platform en dashboards",
                        8: "Terraform, Consul, Ansible",
                        9: "Schrijven en bijwerken van Ansible-rollen en playbooks",
                    },
                },
                description2: {
                    title: "Ideaal gezien beschik je over de volgende vaardigheden:",
                    items: {
                        1: "Ervaring met (Debian) Linux-servers in een serieuze productieomgeving",
                        2: "Je bent zen en kunt kalm blijven als er iets misgaat en je kunt goed ingrijpen of de-escaleren na goed overwegen (eerlijk gezegd gebeurt dit bijna nooit - maak je geen zorgen)",
                        3: "Dit zou je doen opvallen: ervaring met diensten zoals Kinesis en datascience op AWS, aangezien we dat momenteel niet intern hebben",
                    },
                },
                description3: {
                    title: "Wat we echt nodig hebben:",
                    items: {
                        1: "Iemand die in staat is om een overzicht te krijgen van hoe alles bij elkaar past",
                        2: "Een gepassioneerde engineer die snel nieuwe dingen kan leren indien dat nodig is",
                    },
                },
                description4: {
                    title: "Maak je geen zorgen over:",
                    items: {
                        1: "Ervaring hebben met alles wat we noemen",
                        2: "je jezelf alleen in enkele van de bovengenoemde activiteiten herkent.",
                    },
                },
            },
        },
    },

    // -----------------------------------------------------------

    english: {
        nav: ["NextGear", "Cases", "About us", "Contact"],
        footer: {
            col1: {
                title: "Would you like to work together?",
                text: "Let's get started!\n\nCall us",
                phone: "020-7881300",
            },
            col2: {
                title: "New business?",
                text: "Mail Carlo at ",
                mail: "carlo@nextgear.nl",
            },
            col3: {
                title: "Collaborate?",
                text: "Mail Frederik at ",
                mail: "frederik@nextgear.nl",
            },
            col4: {
                title: "Want to be part of an amazing team?",
                text: "Take a look at our vacancies at ",
                page: "jobs",
            },
        },
        home: {
            hero: {
                title: "We build",
                typed1: "platforms",
                typed2: "apps",
                typed3: "websites",
                typed4: "integrations",
                text: "NextGear has been working on sustainable IT solutions for today and tomorrow.",
            },
            col: [
                {
                    title: "Platforms",
                    text: "More overview and time thanks to smarter software.",
                    button: "More",
                    buttonUrl: "/platforms",
                },
                {
                    title: "Refactoring",
                    text: "Breathing new life into outdated systems with user-friendly and sustainable technology.",
                    button: "More",
                    buttonUrl: "/refactoring",
                },
                {
                    title: "Strategy",
                    text: "Improving your business processes together with NextGear.",
                    button: "More",
                    buttonUrl: "/strategy",
                },
            ],
            clients: {
                title: "We work with",
            },
            content: {
                title: "...",
                text: "...",
            },
        },
        cases: {
            all: {
                hero: {
                    title: "All cases",
                },
                thumbnails: [
                    {
                        name: "NextXS",
                        title: "The key to the future",
                        image: thumbnailNextXS,
                        url: "/nextxs",
                    },
                    {
                        name: "Voyp",
                        title: "Invoicing at the touch of a button",
                        image: thumbnailVoyp,
                        url: "/voyp",
                    },
                    {
                        name: "Finalyser",
                        title: "Overview in your administrations",
                        image: thumbnailFinalyser,
                        url: "/finalyser",
                    },
                    {
                        name: "Kugis",
                        title: "State of the art art-lending software",
                        image: thumbnailKugis,
                        url: "/kugis",
                    },
                    {
                        name: "Avitms",
                        title: "The complete training booking system",
                        image: thumbnailAviTMS,
                        url: "/avitms",
                    },
                    {
                        name: "The New Worker",
                        title: "Flexible workspace even easier",
                        image: thumbnailTheNewWorker,
                        url: "/thenewworker",
                    },
                    {
                        name: "FXDC",
                        title: "Continues where regular parcel delivery stops",
                        image: thumbnailFXDC,
                        url: "/fxdc",
                    },
                    {
                        name: "NextGear Fastlane",
                        title: "Beautiful presence websites in a fraction of time",
                        image: thumbnailWebsites,
                        url: "/websites",
                    },
                ],
            },
            platforms: {
                hero: {
                    title: "Platforms",
                },
                thumbnails: [
                    {
                        name: "Voyp",
                        title: "Invoicing at the touch of a button",
                        image: thumbnailVoyp,
                        url: "/voyp",
                    },
                    {
                        name: "Finalyser",
                        title: "Overview in your administrations",
                        image: thumbnailFinalyser,
                        url: "/finalyser",
                    },
                    {
                        name: "The New Worker",
                        title: "Flexible workspace even easier",
                        image: thumbnailTheNewWorker,
                        url: "/thenewworker",
                    },
                    {
                        name: "NextXS",
                        title: "The key to the future",
                        image: thumbnailNextXS,
                        url: "/nextxs",
                    },
                ],
            },
            aviation: {
                hero: {
                    title: "Aviation",
                },
                thumbnails: [
                    {
                        name: "AviTMS",
                        title: "The complete training booking system",
                        image: thumbnailAviTMS,
                        url: "/avitms",
                    },
                ],
            },
            strategy: {
                hero: {
                    title: "Strategy",
                },
                thumbnails: [
                    {
                        name: "NextXS",
                        title: "The key to the future",
                        image: thumbnailNextXS,
                        url: "/nextxs",
                    },
                    {
                        name: "FXDC",
                        title: "Continues where regular parcel delivery stops",
                        image: thumbnailFXDC,
                        url: "/fxdc",
                    },
                ],
            },
            refactoring: {
                hero: {
                    title: "Refactoring",
                },
                thumbnails: [
                    {
                        name: "Kugis",
                        title: "State of the art art-lending software",
                        image: thumbnailKugis,
                        url: "/kugis",
                    },
                    {
                        name: "NextGear Fastlane",
                        title: "Beautiful presence websites in a fraction of time",
                        image: thumbnailWebsites,
                        url: "/websites",
                    },
                ],
            },
        },
        case: {
            nextxs: {
                casehero: {
                    client: "NextXS",
                    title: "The key to the future",
                    type: "Design / App / Hardware / Web development",
                },
                info_1: {
                    title: "Special software for beautiful hardware",
                    text: "NextXS is an open platform for modern access control: access management, online/offline locks, hardware agnostic and easy to use. Various links are possible through the API. NextGear is responsible for the technical strategy and development of multiple assets on behalf of NextXS, including but not limited to the end-user mobile app, the lock- configuration app, dashboard control, XS alert and the API.",
                },
                info_2: {
                    title: "Safety without the hassle",
                    text: "NextXS relieves customers of the burden with the platform developed by NextGear in safely accessing every conceivable space in multiple buildings. Users only need one account to access rooms in their offices at various organizations. NextXS is flexible, scalable, and safe. Modern security based on proven encryption algorithms ensures that you can close the door behind you with peace of mind.",
                },
                info_3: {
                    title: "XS Alert",
                    text: "The flexible platform of Next XS also has the attention of healthcare. Vital innovation is taking place with partners such as Kepler and Hikvision. XS Alert has been developed to reliably forward care notifications to the responsible care provider. Due to this development,  care notifications can be sent utilizing the hardware and software of, for example, Hikvision and Kepler if a patient has fallen out of bed. XS Alert is designed just as flexibly as Next XS. The system can receive and forward a multitude of care notifications. The XS Alert Endpoint makes it easy to integrate with the care notification system. Our solutions are secure, technology agnostic and scalable. Many customers opt for a tailor-made implementation, a partnership in which we work together on the best solution; for now, and tomorrow.",
                },
            },
            voyp: {
                casehero: {
                    client: "Voyp",
                    title: "Invoicing at the touch of a button",
                    type: "Design / Integrations / Web development",
                    image: thumbnailVoyp,
                },
                info_1: {
                    title: "Time-saving Integration",
                    text: "Voyp provides business telephony. The entire workflow of their services is handled in a CRM. This is where all customer contact, hardware orders, the composition of the subscription, and all other details are archived. The invoices are automatically issued every first Monday of the month, which are composed of various parts. A time-consuming and error-prone operation that was previously performed by hand.",
                },
                info_2: {
                    title: "From CRM system directly into your administration",
                    text: "NextGear has automated the entire billing process. The solution extracts the required information from the required data source and shows a totalization of the invoices for parts that Voyp can process quickly. If this is in order, draft invoices are created in the accounting package. These can easily be removed if the consulted data sources have not yet been completely updated. If all is well, all invoices can be quickly approved and sent out digitally at the push of a button.",
                },
                quote: {
                    words: "’Thanks to NextGear’s solution, the billing process of a days-long and excruciatingly tedious process is simplified and fully automated. This has greatly reduced the error sensitivity.",
                    author: "Michael Jansen",
                    profession: "Co-founder at Voyp",
                },
            },
            finalyser: {
                casehero: {
                    client: "Finalyser",
                    title: "Overview in your administrations",
                    type: "Design / Coupli / Web development",
                    image: thumbnailFinalyser,
                },
                info_1: {
                    title: "The smartest Moneybird integration",
                    text: "NextFin is a financial and administrative office specialized in the Moneybird accounting package. Moneybird offers a very user-friendly package with many options. However, it is difficult to see how healthy your administration is: how many transactions do I still have to book? Are there receipts missing? Have I booked correctly? Can I now submit the VAT return with peace of mind?",
                },
                info_2: {
                    title: "Receipts bonanza",
                    text: "For accounting firms that support customers with Moneybird, this challenge is even more significant. they needIt is important for them to know in advancegood time which administrations still require a lot of attention to organize the work properly. Moreover, it is helpful to signal that the customer still has to submit the necessary receipts timely.",
                },
                info_3: {
                    title: "Faster, Better, Cleaner",
                    text: "Finalyser offers a range of power tools to make this possible. At a glance, you can see in which administrations things are going well and which require more attention. As the fiscal month approaches, you can visualize timely which receipts still need to be traced for a correct VAT return. Finalyser is highly configurable. For example, you can activate all kinds of checks at the general ledger level that help you book more accurately and faster.",
                },
                quote: {
                    words: "It is almost impossible to make mistakes with Finalyser.",
                    author: "Thiemo Stougie",
                    profession: "Founder ROAR! Concepts",
                },
            },
            avitms: {
                casehero: {
                    client: "AviTMS",
                    title: "The complete training booking system",
                    type: "Web development / Integrations",
                    image: thumbnailAviTMS,
                },
                info_1: {
                    title: "From A to Z",
                    text: "Since 2006, NextGear has been active in the aviation industry. We have developed an extensive training management system for training organizations specialized in the aviation industry. Using our software, they can handle the entire workflow reliably and in accordance with international standards.",
                },
                info_2: {
                    title: "Integral solution",
                    text: "The software offers possibilities for registration of students, management of the course material, testing, examination and certification, instructor classification, classroom management, a complete accounting of students and instructors, internal and external audits and quality management (KPIs). A diverse number of customers at home and abroad use this solution. Designed to meet the strict requirements of the Internation Civil Aviation Organization, the AvITMS is currently active in the Netherlands, Germany, Spain and Morocco.",
                },
                quote: {
                    words: "The ITMS is not only an excellent centralised system into which all our processes are integrated but also a fantastic managerial tool supporting our successful growing activities as Training Center of Excellence. We are therefore pleased to make this potent integrated training management system available, through Software Development Company NextGear BV, to training organisations committed to optimal, efficient performance in delivering training courses ultimately based on high-quality international standards.",
                    author: "Paula de Almeida",
                    profession: "Director Jaato",
                },
            },
            kugis: {
                casehero: {
                    client: "Kugis",
                    title: "State of the art art-lending software",
                    type: "A group of art lending organizations were confronted with one of the most common challenges for more prominent organizations, the challenge of time. The software they used was outdated and difficult to update. The group set up a purchasing organization to solve this challenge. They formulated wishes and entered the market with them. After the necessary discussions, they opted for NextGear to realize a new and modern system for the near future.",
                },
                info_1: {
                    title: "Lend or buy? No problem!",
                    text: " An art library wants to be free in how art is lent but must comply with all regulations. This means that the module for the subscriptions must offer this freedom and provide insight and control. For example, there may be special subscriptions for specific target groups, flexible discounts, changing realities regarding taxation or the purchase of accessories related to art rental. The intention is that the group builds up an extensive art catalogue that can also be consulted via the internet. All processes in the organization are handled via this new package. The new solution makes renting a breeze and greatly improves the efficiency of accountability.",
                },
                quote: {
                    words: "...",
                    author: "...",
                    profession: "...",
                },
            },
            thenewworker: {
                casehero: {
                    client: "The New Worker",
                    title: "Flexible workspace even easier",
                    type: "Design / Koppeling Web / development",
                    image: thumbnailTheNewWorker,
                },
                info_1: {
                    title: "Make real estate work",
                    text: "The New Worker provides solutions for property owners, property managers and facility managers. The New Worker platform enables sharing (empty) office spaces and workplaces within companies or between affiliated companies.",
                },
                info_2: {
                    title: "More reservations",
                    text: "The TNW participants can easily see where workplaces are available, which facilities are available and can easily reserve the workplace. On the day of the reservation, the participant will receive instructions (from route to WiFi data) and a digital key. The participant checks in, sits down and can get to work.",
                },
                info_3: {
                    title: "Access and overview",
                    text: "NextGear has realized the booking and management platform as well as the link with the NextXS API so that the generation and control of digital access to the workplace is fully integrated. Statistics on usage are available to the landlord, and the employer can see who uses the service to what extent.",
                },
                info_4: {
                    title: "Easy implementation",
                    text: "The platform for Applied Sciences is operated by themselves but has also been developed in such a way that other organizations in an adapted form can reuse the software. Because of the pandemic, the workplace challenges are numerous and present differently at nationally operating companies and organizations that rent out flexible workplaces.",
                },
                quote: {
                    words: "",
                    author: "",
                    profession: "",
                },
            },
            fxdc: {
                casehero: {
                    client: "FXDC",
                    title: "Continues where regular parcel delivery stops",
                    type: "Web development / Koppeling",
                    image: thumbnailAviTMS,
                },
                info_1: {
                    title: "Special delivery",
                    text: "FXDC is a company with offices in Bonaire, St. Eustatius and Saba. They provide various customers with exceptional services in letter and parcel post, such as PO boxes, international priority shipments and parcel shipping.",
                },
                info_2: {
                    title: "Where is my package?",
                    text: "Customers of FXDC Europoint would like to be able to shop in Europe. However, these do not deliver to the islands. Through the portal of FXDC Europoint developed by NextGear, customers can register their e-commerce orders, have them shipped to a drop off point of FXDC Europoint, which in turn delivers the shipment to them. Customers can, of course, follow their shipments neatly via track & trace. FXDC can change the status of the shipments and keep all the necessary administrative processes in this portal. The solution is flexible. This way, we ensure that the software is prepared for future adjustments.",
                },
                quote: {
                    words: "",
                    author: "",
                    profession: "",
                },
            },
            websites: {
                casehero: {
                    client: "NextGear Fastlane",
                    title: "Beautiful presence websites in a fraction of time",
                    type: "Design / Web development",
                    image: thumbnailWebsites,
                },
                info_1: {
                    title: "Short lead time, less expense.",
                    text: "With Fastlane, we can realise websites in a short amount of time that gives companies the face they deserve. We came up with a method we'd like to call Fastlane. Fastlane ensures we can provide our customers with a brand new website in a short lead time via a clear method and even clearer rates.",
                },
                info_2: {
                    title: "Wix website maker",
                    text: "In order to work as quickly and efficiently as possible, we use the online tool Wix for the development of Fastlane sites. This tool enables our developers to quickly deliver complete responsive websites that can also be easily managed and modified by us.",
                },
                info_3: {
                    title: "Prices",
                    text: "We make a clear proposal for each request based on the number of pages. We work out a concept design and, after our concept has been accepted, we work out the remaining pages. Two correction rounds are included for each page. In this way, we can process feedback swiftly and implement new insights that have arisen during the project. After launching the website, we offer affordable prepaid cards for any maintenance work or changes to the website.",
                },
                info_4: {
                    title: "They already tried",
                    text: "Examples of websites that we have created using the Fastlane method are nextfin.nl, rssf.nl and finalyser.nl",
                },
                quote: {
                    words: "...",
                    author: "...",
                    profession: "...",
                },
            },
        },
        about: {
            hero: {
                title: "We are",
                typed1: "developers",
                typed2: "designers",
                typed3: "strategists",
                typed4: "partners",
                text: "At NextGear, we like to think along with you. We can convert your ideas into a working product. Do you want to find out what we can do for you? Come and visit us at our office!",
            },
            ourTeam: {
                title: "Our team",
            },
            member: [
                {
                    name: "Jur",
                    job: "Developer",
                },
                {
                    name: "Stefan",
                    job: "Developer",
                },
                {
                    name: "Lisanne",
                    job: "Designer/Developer",
                },
                {
                    name: "Jochem",
                    job: "Head of Tech",
                },
                {
                    name: "Jean-Micheal",
                    job: "Head of Finance/Operations ",
                },
                {
                    name: "Remy",
                    job: "Project Manager/Developer",
                },
                {
                    name: "Frederik",
                    job: "Founder of NextGear",
                },
                {
                    name: "Bram",
                    job: "Operations/Legal",
                },
                {
                    name: "Pim",
                    job: "Management Assistent",
                },
                {
                    name: "Vincent",
                    job: "Developer",
                },
                {
                    name: "Tarquin",
                    job: "Developer",
                },
                {
                    name: "Monster",
                    job: "Office Dog",
                },
            ],
            content: {
                title: "20 years of experience",
                text: "For more than 20 years, NextGear has been building digital solutions for versatile clients from different industries.",
            },
        },
        contact: {
            hero: {
                title: "Nice to meet you",
                text: "Do you have a good idea, a technical challenge or do you just want to come and have a cup of coffee? No problem! Visit us in our office in the centre of Amsterdam.",
            },
            col: [
                {
                    title: "Contact",
                    text: "We are here for you",
                    mail: "info@nextgear.nl",
                    phone: "020-7881300",
                },
                {
                    title: "Address",
                    text: "Nieuwe Herengracht 123a \n 1011SC Amsterdam",
                    link: "Google maps",
                    linkUrl:
                        "https://www.google.nl/maps/place/Nieuwe+Herengracht+123A,+1011+SC+Amsterdam/@52.3684899,4.9060597,17z/data=!3m1!4b1!4m5!3m4!1s0x47c609a2d498994d:0x6dad297d23035a5f!8m2!3d52.3684899!4d4.9082484",
                },
                {
                    title: "Social",
                    text: "Follow us",
                    link: "LinkedIn",
                    linkUrl: "https://www.linkedin.com/company/nextgear/",
                },
            ],
        },
        jobs: {
            hero: {
                title: "Join us for",
                typed1: "work",
                typed2: "self-development",
                typed3: "your career",
                typed4: "coffee",
                text: "Join our innovative team and bring your ideas to life. Explore our current job opportunities and find out how you can be a part of our exciting journey.",
            },
            header: {
                title: "Wanted: a talented designer, a skilled backender and a savvy platform engineer",
                text: "We're on the lookout for three people to join the NextGear team. We're doing awesome and need some extra hands to keep up the momentum. That's where you come in!",
            },
            description1: {
                title: "Who are we?",
                text: "NextGear was founded back in 2003, and we've recently shifted our focus to building our own product instead of working for clients. We're proud to have a team of eight motivated and young tech enthusiasts between 20 and 40, who work on everything from design and frontend to backend and infrastructure. Our exciting new platform, which we launched last year, has exceeded all our expectations, and now we need to grow our team to help us take it to the next level.",
            },
            description2: {
                title: "What‘s the job?",
                text: "In short: we're looking for a designer, a backender, and a platform engineer who can help us improve our platform. After an intense period, where the main goal was to launch as quickly as possible, we are now keen on improving the platform. This applies as much to the UI as it does for anything behind the scenes. We always have to keep an eye on security and reliability due to the amount of money that is processed via the platform, while also keeping an eye on usability and performance, since we receive up to 10k backend requests per minute, a number that keeps going up.",
            },
            description3: {
                title: "Where?",
                text: "We're a hybrid-remote team that values flexibility. Our goal is to see each other at least once a week, because we feel that helps to stay in tune. Other than that, you can work from home or from our office located in the heart of Amsterdam. We're a diverse group of people who love to work hard and play even harder. We offer a Dutch lunch - or Surinamese food on the first Monday of every month, and we have an excellent espresso machine for coffee lovers (but don't worry, we have tea too). We're also a safe, open, and transparent community, and we have the occasional furry friend in the office.",
            },
            description4: {
                title: "Give me some details…",
                text: "For each role, we have a more detailed description below. At the moment our focus is on gradually replacing a frontend written in Django by a React-based frontend. This is why the last people to join us in 2022 were both frontenders.\nThe new frontend will allow us to scale up further. It connects to an API that we are adding to the existing Django project, after which we’ll remove the existing frontend code from the Django project. Meanwhile, we’re writing new functionality in plain Django, which may be moved to React later on. The rewrite also offers us the opportunity to reflect on, and improve, on the current design and usability.",
            },
            quote: {
                words: "As a NextGear developer, I thrive on the excitement of coding and transforming ideas into reality. With passion and curiosity as my driving forces, I am proud to be part of this dynamic team that fosters innovation and empowers me to make a meaningful impact in the world of software development.",
                author: "Stefan",
                profession: "Developer",
            },
            apply: {
                title: "How to apply?",
                text: "To apply, please email your resume and cover letter to:",
            },
            designer: {
                title: "> Designer",
                text: "Unleash your creative genius! Join our team as a designer and craft visually stunning designs that captivate audiences and elevate our brand to new heights.",
                description1: {
                    title: "Your activities will consist of:",
                    items: {
                        1: "Website design (and frontend) for NextGear clients",
                        2: "Smaller graphic assignments such as logos, promotional materials for socials or a roll-up banner",
                        3: "Designing solutions for UI/UX issues within our products",
                        4: "Brainstorming sessions with colleagues for improving our products",
                    },
                },
                description2: {
                    title: "Ideally you’d have a skillset like this:",
                    items: {
                        1: "Experience with Adobe (mainly Adobe XD, Illustrator and Photoshop)",
                        2: "This would make you stand out: knowledge of frontend techniques (i.e. HTML/CSS/JS and relevant tools or frameworks)",
                    },
                },
                description3: {
                    title: "What we really need:",
                    items: {
                        1: "Someone with a good sense of design",
                        2: "Someone to relieve load that can be a great sparring partner",
                        3: "Someone that can make an actual contribution",
                    },
                },
                description4: {
                    title: "Don’t fuss about:",
                    items: {
                        1: "Having 18 years of experience, we’re not looking for a veteran",
                    },
                },
            },
            backender: {
                title: "> Backender",
                text: "Power the web with your back-end expertise! Join our team as a back-end developer and shape the future of web development with cutting-edge technologies and exciting projects.",
                description1: {
                    title: "Your activities will consist of:",
                    items: {
                        1: "Working on new features for existing project components",
                        2: "Being responsible for native Django views (model/view/template) and/or admin",
                        3: "REST framework endpoints",
                        4: "Interfacing with existing APIs for payment providers and other 3rd parties",
                        5: "Writing (new) microservices (plain Python or Flask)",
                        6: "Doing data science stuff",
                        7: "Reviewing merge requests",
                        8: "Refactoring and writings tests",
                        9: "CI config",
                        10: "…honestly there’s a lot we want to do, we’re full of plans and ideas",
                    },
                },
                description2: {
                    title: "Ideally you’d have a skillset like this:",
                    items: {
                        1: "Experience with Python/Django (we try to follow PEP8)",
                        2: "Knowledge of common design patterns",
                        3: "This would make you stand out: you have experience with projects running on AWS (e.g. S3 implementation or Rekognition usage)",
                    },
                },
                description3: {
                    title: "What we really need:",
                    items: {
                        1: "An experienced programmer",
                        2: "Being able to think outside the box of your own tasks",
                    },
                },
                description4: {
                    title: "Don’t fuss about:",
                    items: {
                        1: "Not knowing Django when you have experience with Python",
                        2: "Only feeling fit for some on the activities we mention",
                    },
                },
            },
            platformengineer: {
                title: "> Platform engineer",
                text: "Revolutionize our platform as a platform engineer! Join our team to design and implement cutting-edge systems that drive seamless user experiences and shape the future of our innovative platform.",
                description1: {
                    title: "Your activities will consist of:",
                    items: {
                        1: "The day to day operations; updates, monitoring, security, detecting anomalies",
                        2: "Managing a platform spread over multiple AWS accounts",
                        3: "Tidying things up",
                        4: "Keeping staging and production environments in sync",
                        5: "Setting up and managing a data platform",
                        6: "Platform-wide centralised logging using syslogd",
                        7: "Grafana platform and dashboards",
                        8: "Terraform, Consul, Ansible",
                        9: "Writing and updating Ansible roles and playbooks",
                    },
                },
                description2: {
                    title: "Ideally you’d have a skillset like this:",
                    items: {
                        1: "Experience with (Debian) Linux servers in a serious production environment",
                        2: "You’re very zen and good at remaining calm if (and when) something goes wrong and you’re able to intervene or (de)escalate well-considered but quickly (honestly, this almost never happens - don’t worry)",
                        3: "This would make you stand out: experience with services like Kinesis and data science on AWS as we currently don't have that in house",
                    },
                },
                description3: {
                    title: "What we really need:",
                    items: {
                        1: "Someone who is able to gather an overview of how everything fits together",
                        2: "A passionate engineer who can quickly learn new things when necessary",
                    },
                },
                description4: {
                    title: "Don’t fuss about:",
                    items: {
                        1: "Having experience with everything we mention",
                        2: "Only feeling fit for some on the activities we mention",
                    },
                },
            },
        },
    },
};
