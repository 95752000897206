import React from "react";
import "../../../index.css";
import "./info.css";

const Info = ({ title, texts, noPaddingTop }) => (
    <div className="info white-block bullet">
        {noPaddingTop ? (
            <div className="inner no-padding-top">
                <h2>{title}</h2>
                {texts.map((item, index) => {
                    return (
                        <div className="bullet-item" key={index}>
                            <p className="bullet-dot">•</p> <p>{item}</p>
                        </div>
                    );
                })}
            </div>
        ) : (
            <div className="inner">
                <h2>{title}</h2>
                {texts.map((item, index) => {
                    return (
                        <div className="bullet-item" key={index}>
                            <p className="bullet-dot">•</p> <p>{item}</p>
                        </div>
                    );
                })}
            </div>
        )}
    </div>
);

export default Info;
