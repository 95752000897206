import React from "react";
import "../../../index.css";
import "./info.css";

const Info = ({ title, text, email, noPaddingTop }) => (
    <div className="info white-block">
        {noPaddingTop ? (
            <div className="inner no-padding-top">
                <h2>{title}</h2>
                <p>{text}</p>
                {email}
            </div>
        ) : (
            <div className="inner">
                <h2>{title}</h2>
                <p>{text}</p>
            </div>
        )}
    </div>
);

export default Info;
